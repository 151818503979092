.BgBush {
  position: relative;

  padding: 0;

  background-color: var(--clr-4);
}
.bg-bush {
  position: absolute;
  z-index: 0;
  bottom: -5rem;

  width: 105vw;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .bg-bush {
    position: absolute;
    z-index: 0;
    bottom: -3rem;

    width: 105vw;
  }
}

@media screen and (max-width: 1000px) {
  .bg-bush {
    position: absolute;
    z-index: 0;
    bottom: -3rem;

    width: 200vw;
  }
}
