.Hero01 {
  position: relative;

  color: var(--clr-1);
  background-image: url(../img/bg/bg-home.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Hero01 h1 {
  color: var(--clr-2);

  -webkit-text-fill-color: var(--clr-2);
  -webkit-text-stroke: 3px var(--clr-1);
}

.Hero01 h2 {
  color: var(--clr-2);

  -webkit-text-fill-color: var(--clr-2);
  -webkit-text-stroke: 2px var(--clr-1);
}

.Hero01-box {
  position: relative;
  z-index: 1;
  width: 100%;

  display: grid;
  grid-template-columns: repeat(2, calc(50% - 1rem));
  gap: 2rem;
  justify-content: center;
  align-items: center;
}
.Hero01-box > div:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1rem;
}
.Hero01-box img {
  width: 100%;
  margin-bottom: -10rem;
}

/*-- responsive --*/
@media screen and (max-width: 1300px) {
  .Hero01-box {
    gap: 0;
  }
}

@media screen and (max-width: 1000px) {
  .Hero01 {
    padding: 1rem 1rem 3rem;
  }
  .Hero01 h1,
  .Hero01 h2 {
    color: var(--clr-2);

    -webkit-text-fill-color: var(--clr-2);
    -webkit-text-stroke: 1px var(--clr-1);
  }

  .Hero01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
  .Hero01-box > div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
  }
  .Hero01-box img {
    width: 100%;
    margin-bottom: unset;
  }
}
