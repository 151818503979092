.HowTo01 {
  padding: 5rem 1rem 10rem;

  background-color: var(--clr-4);
  background-image: url(../img/bg/bg-road.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.HowTo01-box {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;
}
.HowTo01-box h3 {
  font-size: 3rem;
  color: var(--clr-7);
}
.HowTo01-box p span {
  color: var(--clr-1);
}

.HowTo01-box > img {
  width: 420px;
}

.HowTo01-box > div {
  width: 100%;
  height: 100%;
  padding: 2rem;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 2rem;

  background-color: var(--clr-3);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0rem 0.5rem var(--clr-1);
}
.HowTo01-box > div > div {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 2rem;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .HowTo01 {
    padding: 3rem 1rem 5rem;
  }

  .HowTo01-box {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .HowTo01-box > img {
    width: 80%;
    margin-top: -5rem;
  }
}
