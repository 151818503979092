.Stats01 {
  padding: 10rem 1rem 8rem;
  background-color: var(--clr-2);
}

.Stats01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2.33rem));
  gap: 4rem;
  justify-content: center;
  align-items: center;
}
.Stats01-box > div {
  width: 100%;

  padding: 2rem;

  background-color: var(--clr-3);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0 0.5rem var(--clr-1);
}
.Stats01-box > div:nth-child(1) {
  background-color: var(--clr-5);
}
.Stats01-box > div:nth-child(2) {
  background-color: var(--clr-7);
}
.Stats01-box > div:nth-child(3) {
  background-color: var(--clr-3);
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .Stats01-box {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 1.33rem));
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .Stats01 {
    padding: 8rem 1rem 5rem;
  }

  .Stats01-box {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .Stats01-box > div:nth-child(2) {
    grid-row-start: 1;
  }
}
