.Join01 {
  padding: 6rem 1rem 8rem;

  background-image: url(../img/bg/bg-01.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.Join01-box {
  width: 100%;
  padding: 2rem 5rem;

  background-color: var(--clr-3);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0 0.5rem var(--clr-1);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5rem;

  text-align: center;
}

.Join01-box > div:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
  text-align: left;
}

.Join01-box > div:nth-child(1) > .btnFlex {
  width: 100%;
  margin-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
}
.Join01-box > img {
  width: 500px;
  margin-top: -8rem;
  margin-bottom: -8rem;
}

/*-- responsive --*/

@media screen and (max-width: 1000px) {
  .Join01 {
    padding: 0 1rem 3rem;
  }
  .Join01-box {
    width: 100%;
    padding: 2rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0;

    text-align: center;
  }

  .Join01-box > div:nth-child(1) {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    text-align: center;
  }

  .Join01-box > div:nth-child(1) > .btnFlex {
    width: 100%;
    margin-top: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .Join01-box > img {
    width: 100%;
    margin-top: 0;
    margin-bottom: -6rem;
  }
}
