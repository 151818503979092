.Token01 {
  padding: 10rem 1rem 2rem;
  background-color: var(--clr-2);
}

.Token01-box {
  width: 100%;

  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 2.33rem));
  gap: 4rem;
  justify-content: center;
  align-items: center;
}
.Token01-box > div {
  width: 100%;
  padding: 2rem;

  background-color: var(--clr-3);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0 0.5rem var(--clr-1);
}
.Token01-box > div > img {
  width: 100%;
}

.Token01-box > div:nth-child(2) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.5rem;
}
.Token01-box > div:nth-child(2) > div {
  width: 100%;
  padding: 0.5rem 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;

  color: var(--clr-1);
  background-color: var(--clr-4);
  border-radius: 5px;
}
.Token01-box > div:nth-child(2) > div:last-child {
  color: var(--clr-1);
  background-color: transparent;
  border-radius: 5px;
}
.Token01-box > div:nth-child(2) > div.ca {
  background-color: var(--clr-2);

  overflow: hidden;
}
.Token01-box > div:nth-child(2) > div p {
  color: var(--clr-1);
}
.Token01-box > div:nth-child(2) > .btnFlex {
  width: 100%;

  display: flex;
  flex-direction: column;
}
.Token01-box > div:nth-child(2) > .btnFlex a,
.Token01-box > div:nth-child(2) > .btnFlex button {
  width: 100%;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
  .Token01-box {
    width: 100%;

    display: grid;
    grid-template-columns: repeat(3, calc(33.33% - 1.33rem));
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 1000px) {
  .Token01 {
    padding: 5rem 1rem 3rem;
  }

  .Token01-box {
    width: 100%;

    display: grid;
    grid-template-columns: 100%;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }
  .Token01-box > div {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
  }
  .Token01-box > div:nth-child(1) {
    padding: 0 2rem;
  }
  .Token01-box > div:nth-child(1) img {
    width: 100%;
    margin-top: -2rem;
  }
  .Token01-box > div:nth-child(3) {
    padding: 0 2rem;
  }
  .Token01-box > div:nth-child(3) img {
    width: 100%;
    margin-top: -2rem;
  }
}
