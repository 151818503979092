.About01 {
  padding: 8rem 1rem 5rem;

  background-color: var(--clr-4);
}

.About01-box {
  width: 100%;
  margin-bottom: -8rem;
  padding: 5rem;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 1rem;

  background-color: var(--clr-3);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0 1rem var(--clr-1);
}
.About01-box > div.ca {
  width: 100%;
  padding: 0.5rem 2rem;

  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 1rem;

  background-color: var(--clr-2);
  border: solid 4px var(--clr-1);
  border-radius: 1rem;
  box-shadow: 0 0.5rem var(--clr-1);

  overflow: hidden;
}

/*-- responsive --*/

@media screen and (max-width: 1300px) {
}

@media screen and (max-width: 1000px) {
  .About01 {
    padding: 5rem 1rem 3rem;
  }
  .About01-box {
    width: 100%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }
  .About01-box > div.ca {
    width: 100%;
    padding: 0.5rem 1rem;

    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    gap: 1rem;
  }
}
